<template>
  <div>
    <q-form @submit="beforeSave">
      <q-card flat class="q-mt-md">
        <q-card-section class="row items-center q-col-gutter-md q-pa-none">
          <div class="col-6">
            <q-select
              use-input
              v-model="form.commercialManager"
              @filter="filterManager"
              :options="managersFiltered"
              option-label="name"
              ref=""
              outlined
              clear-icon="mdi-close"
              clearable
              option-value="id"
              label="Alterar Comercial"
              lazy-rules
              :rules="[(val) => !!val || 'Field is required']"
            />
          </div>

          <div class="col-6">
            <q-select
              v-model="form.advertiser"
              :options="advertiser"
              option-label="trademark"
              outlined
              clear-icon="mdi-close"
              clearable
              option-value="id"
              label="Alterar Anunciante"
              lazy-rules
              :rules="[(val) => !!val || 'Field is required']"
            />
          </div>
        </q-card-section>
        <q-card-section class="row items-center q-col-gutter-md q-mt-sm q-pa-none">
          <div class="col-6">
            <q-select
              use-input
              v-model="form.clientManager"
              @filter="filterManager"
              :options="managersFiltered"
              option-label="name"
              outlined
              clear-icon="mdi-close"
              clearable
              option-value="id"
              label="Alterar Atendimento"
              lazy-rules
              :rules="[(val) => !!val || 'Field is required']"
            />
          </div>

          <div class="col-6">
            <q-select
              v-model="statusAtual"
              :options="status"
              outlined
              clear-icon="mdi-close"
              clearable
              label="Alterar Status"
              lazy-rules
              :rules="[(val) => !!val || 'Field is required']"
            />
          </div>
        </q-card-section>

        <q-card-section class="row items-center q-col-gutter-md q-mt-sm q-pa-none">
          <div class="col-6">
            <q-select
              use-input
              v-model="form.networkManager"
              @filter="filterManager"
              :options="managersFiltered"
              option-label="name"
              outlined
              clear-icon="mdi-close"
              clearable
              option-value="id"
              label="Alterar Afiliados"
              lazy-rules
              :rules="[(val) => !!val || 'Field is required']"
            />
          </div>
        </q-card-section>

        <q-card-section class="row items-center q-col-gutter-md q-mt-sm q-pa-none">
          <div>
            <q-btn unelevated no-caps color="primary" padding="sm lg" label="Salvar" type="submit" />
          </div>
        </q-card-section>
      </q-card>
    </q-form>
  </div>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import AdvertiserService from '../../services/AdvertiserService';

export default {
  name: 'Control',

  mixins: [CampaignMixin, AdvertiserService],

  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },

  created() {
        if (this.campaignSelect) {
            this.getManager();
            this.getAdvertiserName();
        }
        // this.getStatus();
    },

    watch: {
        campaignSelect: {
        handler() {
            this.getManager();
            this.getAdvertiserName();
        },
        deep: true
        }
  },

  data() {
    return {
      tab: 'controle',

      managers: [],
      managersFiltered: [],
      advertiser: [],
      statusAtual: null,

      form: {
        commercialManager: null,
        clientManager: null,
        networkManager: null,
        advertiser: null
      },

      status: [
        {
          value: 0,
          label: 'Pendente'
        },
        {
          value: 1,
          label: 'Ativado'
        },
        {
          value: 2,
          label: 'Pausado'
        },
        {
          value: 3,
          label: 'Desativado'
        },
        {
          value: 4,
          label: 'Em implementação'
        }
      ]
    };
  },

  methods: {
    async getManager() {
      this.onLoading(true);
      try {
        let dataOrder = [];
        const { data, status } = await this.$http.get('/user/managers-unblocked');
        if (status === 200) {
          dataOrder = data.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;

            return 0;
          });
          this.managers = dataOrder;

          this.form.commercialManager = this.managers.find(
             (item) => item.name == this.campaignSelect?.comercial_manager_name
          );

          this.form.clientManager = this.managers.find(
             (item) => item.name == this.campaignSelect?.operational_manager_name
          );

          this.form.networkManager = this.managers.find(
             (item) => item.name == this.campaignSelect?.network_manager_name
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterManager(val, update) {
      update(this.defaultFilter(val, 'managersFiltered', this.managers, 'name'));
    },

    async getAdvertiserName() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=ADVERTISER&path=/api/get/advertiser/bytype/anunciante');
        if (status === 200) {
          this.advertiser = data;

           this.form.advertiser = this.advertiser.find((item) => item.trademark == this.campaignSelect?.advertiser_name);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
            this.getStatus();
            this.onLoading(false);
      }
    },

    getStatus() {
      if (this.campaignSelect) this.statusAtual = this.status.find((item) => item.value == this.campaignSelect.status);
    },

    beforeSave() {
      if (
        this.form.advertiser == null ||
        this.form.commercialManager == null ||
        this.form.clientManager == null ||
        this.statusAtual == null ||
        this.form.networkManager == null
      ) {
        this.errorNotify('Preencha todos os campos');
      }

      let data = {
        advertiser_id: this.form.advertiser.id,
        comercial_manager_id: this.form.commercialManager.user_id,
        operational_manager_id: this.form.clientManager.user_id,
        status: this.statusAtual.value,
        network_manager_id: this.form.networkManager.user_id,
        comercial_manager_name: this.form.commercialManager.name,
        operational_manager_name: this.form.clientManager.name,
        network_manager_name: this.form.networkManager.name
      };

      this.onSave(data);
    },

    async onSave(data) {
      this.onLoading(true);
      try {
        const { status } = await this.editControlCampaign(this.campaignSelect.id, data);

            if (status === 200) {
            await this.getCampaignById(this.id);
            this.successNotify('Controle atualizado com sucesso');
            }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCampaignById(idCampaign) {
      if (idCampaign) {
        this.onLoading(true);

        try {
          const { data, status } = await this.getCampaignDetails(idCampaign);
          if (status === 200) {
            this.ActionSetCampaignSelect(data);
            this.setValuesCampaign();
          }
        } catch (error) {
          if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
        } finally {
          this.onLoading(false);
        }
      }
    },

    setValuesCampaign() {
      if (this.campaignSelect) {
        this.form.name = this.campaignSelect.name;
        this.form.campaign_description = this.campaignSelect.campaign_description;

        this.form.contract = this.campaignSelect.contract;
        this.form.additional_informations = this.campaignSelect.additional_informations;
      }
    }
  }
};
</script>
