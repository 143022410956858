<template>
  <q-form @submit="saveEditionClicked(1)">
    <q-card flat class="row q-col-gutter-md">
      <div class="col-12">
        <q-btn
          v-if="!custom_invoice_exist"
          unelevated
          color="primary"
          no-caps
          label="Nova informação de Faturamento"
          icon="mdi-plus"
          @click="openModel = true"
        />
      </div>

      <q-dialog v-model="openModel" persistent>
        <q-card>
          <q-card-section class="row items-center">
            <span class="q-ml-sm">
              <h6>Adicionar método de Faturamento alternativo</h6>
              <q-separator spaced />
              <FormCampaignBilling :invoice="custom_invoice" />
            </span>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat label="Cancel" class="bg-grey" text-color="white" v-close-popup />
            <q-btn
              flat
              label="Atualizar Informações"
              class="bg-positive"
              text-color="white"
              @click="saveEditionClicked(true)"
              v-close-popup
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-card-section class="col-md-6">
        <q-card :flat="custom_invoice_status != 0" bordered>
          <q-banner :class="custom_invoice_status == 0 ? 'bg-primary' : 'bg-grey-7'" class="text-white">
            <q-radio
              v-model="custom_invoice_status"
              :val=0
              label="Faturamento: Default"
              color="white"
              dark
              @input="saveEditionClicked(custom_invoice_status)"
            />
          </q-banner>
          <FormCampaignBilling :invoice="original_invoice" :disabled="true" />
        </q-card>
      </q-card-section>

      <q-card-section class="col-md-6" v-if="agency_invoice_exist">
        <q-card :flat="custom_invoice_status != 2" bordered>
          <q-banner :class="custom_invoice_status == 2 ? 'bg-primary' : 'bg-grey-7'" class="text-white">
            <q-radio
              v-model="custom_invoice_status"
              :val=2
              label="Faturamento: Agência"
              color="white"
              dark
              @input="saveEditionClicked(custom_invoice_status)"
            />
          </q-banner>

          <FormCampaignBilling :invoice="agency_invoice" :disabled = "true">
            <!-- <template v-slot:footer>
              <div class="col-12 q-px-md q-pb-lg q-gutter-md">
                <q-btn color="negative" unelevated no-caps label="Deletar" @click="deleteClicked" />
                <q-btn color="warning" unelevated no-caps label="Salvar Edição" type="submit" />
              </div>
            </template> -->
          </FormCampaignBilling>
        </q-card>
      </q-card-section>

      <q-card-section class="col-md-6" v-if="custom_invoice_exist">
        <q-card :flat="custom_invoice_status != 1" bordered>
          <q-banner :class="custom_invoice_status == 1 ? 'bg-primary' : 'bg-grey-7'" class="text-white">
            <q-radio
              v-model="custom_invoice_status"
              :val=1
              label="Faturamento: Alternativo"
              color="white"
              dark
              @input="saveEditionClicked(custom_invoice_status)"
            />
          </q-banner>

          <FormCampaignBilling :invoice="custom_invoice">
            <template v-slot:footer>
              <div class="col-12 q-px-md q-pb-lg q-gutter-md">
                <q-btn color="negative" unelevated no-caps label="Deletar" @click="deleteClicked" />
                <q-btn color="warning" unelevated no-caps label="Salvar Edição" type="submit" />
              </div>
            </template>
          </FormCampaignBilling>
        </q-card>
      </q-card-section>

    </q-card>
  </q-form>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import FormCampaignBilling from './FormCampaignBilling.vue';

export default {
  name: 'CampaignBilling',
  components: {
    FormCampaignBilling
  },
  mixins: [CampaignMixin],

  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },
  data() {
    return {
      openModel: false,
      custom_invoice_status: null,
      custom_invoice_exist: false,
      agency_invoice_exist: false,
      original_invoice: {},
      custom_invoice: {},
      agency_invoice: {}
    };
  },

  created() {
    this.getCampaignBillingById(this.id);
  },

  methods: {
    async getCampaignBillingById(campaignId) {
      this.onLoading(true);
      try {
        const { data, status } = await this.listCampaignRevenues(campaignId);
        if (status === 200 || status === 201) {
          this.custom_invoice_status = data.custom_invoice_status;

          if (data.original_invoice)
            this.original_invoice = {
              ...data.original_invoice,
              email: this.isJSON(data.original_invoice.email)
                ? JSON.parse(data.original_invoice.email)[0]
                : data.original_invoice.email
            };
          if (data.custom_invoice) {
            this.custom_invoice = {
              ...data.custom_invoice,
              email: this.isJSON(data.custom_invoice.email)
                ? JSON.parse(data.custom_invoice.email)[0]
                : data.custom_invoice.email
            };
            if (data.custom_invoice_status === 1) this.custom_invoice_exist = true;
          }
          if (data.agency_invoice) {
            this.agency_invoice = {
              ...data.agency_invoice,
              email: this.isJSON(data.agency_invoice.email)
                ? JSON.parse(data.agency_invoice.email)[0]
                : data.agency_invoice.email
            };
            this.agency_invoice_exist = true;
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    isJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },

    async saveEditionClicked(isCreating) {
      console.log('this.custom_invoice_status', this.custom_invoice_status);
      console.log('isCreating', isCreating);

      let parseData = {
        custom_invoice: isCreating,
        campaign_id: this.id,
        deadline: this.custom_invoice.deadline,
        type: this.custom_invoice.type,
        registration_code: this.custom_invoice.registration_code,
        company_name: this.custom_invoice.company_name,
        address: this.custom_invoice.address,
        city: this.custom_invoice.city,
        phone: this.custom_invoice.phone,
        email: this.custom_invoice.email,
        inscricao_estadual: this.custom_invoice.inscricao_estadual,
        inscricao_municipal: this.custom_invoice.inscricao_municipal,
        state: this.custom_invoice.state,
        postal_code: this.custom_invoice.postal_code
      };

      try {
        const { data, status } = await this.updateCustomInvoice(parseData);
        if (status === 200 || status === 201) {
          if (isCreating == 1) {
             this.custom_invoice_status = '1';
             this.custom_invoice_exist = true;
            }
          if (isCreating == 2) {
            this.custom_invoice_status = '2';
            this.agency_invoice_exist = true;
            }
          console.log(data);
          if (data[0] === 1 || data[0] === 2) {
            await this.getCampaignBillingById(this.id);

            this.successNotify('Faturamento editado com sucesso');
          } else {
            this.successNotify(data);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    deleteClicked() {
      this.showDialog('Alerta', 'Tem certeza que deseja apagar faturamento alternativo?', this.onOk, this.onCancel);
    },
    async onOk() {
      console.log('onOk');
      this.onLoading(true);

      try {
        const { status } = await this.deleteInvoice(this.id);
        if (status === 200 || status === 201) {
          this.successNotify('Faturamento excluído.');
          this.custom_invoice_exist = false;
          this.custom_invoice_status = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    onCancel() {
      console.log('onCancel');
    },

    updateInfoBilling() {
      console.log('updateInfoBilling');
    }
  }
};
</script>

<style></style>
