<template>
  <div>
    <div class="row">
      <div class="col-2">
        <div class="q-pa-md q-gutter-y-sm column">
          <q-toggle :label="`Deeplink`" v-model="form.deeplink" />
        </div>
      </div>
      <div class="col-12">
        <div class="q-pa-md q-gutter-y-sm column">
          <q-input
            outlined
            v-model="form.parametros"
            type="text"
            label="?parâmetro1=[variável]&parâmetro2=[variável]&parâmetro3=[variável]*"
             lazy-rules
            :rules="[(val) => !!val || 'Field is required']"
          >
            <template v-slot:append>
              <q-icon
                name="mdi-help-circle-outline"
                class="cursor-pointer"
                size="1.3em"
                color="primary"
                @click="onShowMacros"
              />
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Macros </q-tooltip>
            </template>
          </q-input>
        </div>
      </div>
    </div>
    <div class="row">
      <MacroListCreative :showM="showMacros" @closemacro="showMacros = false" />
    </div>
    <div class="row">
      <div class="col-12">
        <div class="q-pa-md q-gutter-y-sm column">
          <q-input
            v-model="form.authorized_domains"
            outlined
            label="Domínios autorizados: (Separe-os por ';')"
            type="textarea"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="q-pa-md q-gutter-y-sm column">
        <q-btn color="positive" no-caps label="Salvar Alteração" unelevated @click="onSave" />
      </div>
    </div>
  </div>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import DeeplinkService from '../../services/Deeplink';
import MacroListCreative from '../creative/formCreativeTypes/MacroListCreative.vue';

export default {
  components: { MacroListCreative },
  mixins: [CampaignMixin, DeeplinkService],
  created() {
    this.getOneDeeplink();
  },
  props: {
    id: {
      type: [String, Number],
      require: true
    }
  },
  data: function () {
    return {
      showMacros: false,
      form: {
        parametros: null,
        deeplink: false,
        authorized_domains: null
      }
    };
  },
  methods: {
    async getOneDeeplink() {
      this.onLoading(true);
      try {
            const { data, status } = await this.getDeeplink(this.id);
            if (status === 200) {
            this.setParameters(data.parameters);
            this.setAuthorizedDomains(data.authorized_domains);
            this.setDeeplinkStatus();
            }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    setParameters(data) {
      if (this.campaignSelect) {
        this.form.parametros = data;
      }
    },
    setAuthorizedDomains(data) {
      if (this.campaignSelect) {
        this.form.authorized_domains = data;
      }
    },
    setDeeplinkStatus() {
      if (this.campaignSelect) {
        this.form.deeplink = this.campaignSelect.deeplink == 1 ? true : false;
      }
    },
    onClose() {
      this.$router.push({ name: 'Campaigns' });
    },

    async onSave() {
      this.onLoading(true);
      try {
        const { status } = await this.editDeeplink(
          this.form.authorized_domains,
          this.campaignSelect.id,
          this.form.parametros
        );
        const resultFlagDeeplink = await this.editCampaign(this.campaignSelect.id, { deeplink: this.form.deeplink });
        if (status === 200 && resultFlagDeeplink.status === 200) this.successNotify('Deeplink atualizado com sucesso');
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    onShowMacros() {
      this.showMacros = true;
    }
  }
};
</script>

<style></style>
